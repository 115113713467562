import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${theme.below.xl} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${theme.below.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${theme.below.md} {
    grid-template-columns: 1fr 1fr;
  }

  ${theme.below.sm} {
    grid-template-columns: 1fr;
  }

  &:empty {
    display: none;
  }
`;

export const StyledGridCell = styled.div``;

export const GridCell = ({
  children,
  width, // string | number
  xs,
  sm,
  md,
  lg,
  xl,
  align = 'start'
}) => {
  const breakpoints = { xs, sm, md, lg, xl };
  const assignedBreakpoints = Object.keys(breakpoints).reduce(
    (all, key) => ({
      ...all,
      ...(breakpoints[key]
        ? {
            [key]: isNaN(breakpoints[key])
              ? breakpoints[key]
              : `${Number(breakpoints[key]) * 100}%`
          }
        : {})
    }),
    {}
  );
  return (
    <StyledGridCell align={align} xwidth={width} {...assignedBreakpoints}>
      {children}
    </StyledGridCell>
  );
};

export const Grid = ({
  children, // ReactNode
  gap = 'medium' // 'none' | 'small' | 'medium' | 'large'
}) => {
  return <StyledGrid gap={gap}>{children}</StyledGrid>;
};
