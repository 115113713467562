import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ArrowRight.svg';
import { ReactComponent as Accent } from '../../../assets/svg/Accent.svg';
import MaxWidth from '../../../components/Layout/MaxWidth';
import ProductGridCarousel from './ProductGridCarousel';
import useChildren from '../../../hooks/useChildren';

const ProductGridBlockWrapper = styled('div')`
  font-family: 'Barlow';
  font-style: normal;
  color: ${theme.colors.abdDarkGrey};
  background-color: ${theme.colors.abdLightGrey};
  max-height: 620px;
  height: 570px;
`;

const ProductGridContainer = styled('div')`
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  height: 620px;

  u,
  li {
    list-style-type: none;
  }
`;

const Header = styled('div')`
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding-bottom: 32px;
`;

const CarouselContainer = styled('div')`
  height: 400px;
`;

const SeeMore = styled('div')`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding-top: 37px;
  padding-bottom: 40px;
`;

const ProductGridBlock = props => {
  const { header, productComponent, subText } = useChildren(props);
  const isProduct = ({ productUnique }) => productUnique?.articleNumber;
  const mapProducts = ({ productUnique }) => productUnique;
  const products = Array.isArray(productComponent)
    ? productComponent.filter(isProduct).map(mapProducts)
    : [];

  if (!products.length > 0) return null;

  return (
    <ProductGridBlockWrapper>
      <MaxWidth>
        <ProductGridContainer>
          {header && (
            <Header>
              {header?.toUpperCase()} <Accent />
            </Header>
          )}
          <CarouselContainer>
            <ProductGridCarousel products={products} />
          </CarouselContainer>
          {subText && (
            <SeeMore>
              {subText} <ArrowRight />
            </SeeMore>
          )}
        </ProductGridContainer>
      </MaxWidth>
    </ProductGridBlockWrapper>
  );
};

export default ProductGridBlock;
