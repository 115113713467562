import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { ProductCard } from './ProductCard/ProductCard';
import { theme } from '../Theme';
import { Grid, GridCell } from '../ui/Grid';
import { LoadingCard } from './ProductCard/LoadingCard';
import ErrorMessageHandler from './ErrorMessageHandler';
import { EmptyProductCard } from './ProductCard/EmptyProductCard';
import { Above, Below } from '@jetshop/ui/Breakpoints';

export const priceStyle = css`
  [data-flight-price] {
    display: flex;
  }
  .new-price {
    color: #eb0000;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .old-price {
    color: ${theme.colors.darkerGrey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -${theme.space[1]};
  margin-left: -${theme.space[1]};

  .product-card {
    line-height: 19px;
    min-width: 0;

    width: 50%;
    ${theme.above.md} {
      width: 33.333%;
    }
    ${theme.above.lg} {
      width: 25%;
    }

    a {
      margin: 0 0.5em 2em 0.5em;
    }
  }
`;

export function isAboveFold(index) {
  return index < 4;
}

export function ProductGrid({
  products = [],
  listName,
  category = '',
  loading,
  className,
  categoryPath,
  widths = {},
  productGridText,
  categoryTotalResults,
  showHasNoResults = true,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products, category }));
  }, [listName, products, track, category]);

  if (loading) {
    return (
      <Grid
        data-testid="product-grid"
        className={cx('product-grid', className, priceStyle)}
      >
        <GridCell
          key="loading1"
          {...{
            xs: widths.xs || 1 / 2,
            sm: widths.sm,
            md: widths.sm || 1 / 3,
            lg: widths.lg || 1 / 3,
            xl: widths.xl || 1 / 3
          }}
        >
          <LoadingCard />
        </GridCell>
        <GridCell
          key="loading2"
          {...{
            xs: widths.xs || 1 / 2,
            sm: widths.sm,
            md: widths.sm || 1 / 3,
            lg: widths.lg || 1 / 3,
            xl: widths.xl || 1 / 3
          }}
        >
          <LoadingCard />
        </GridCell>
        <GridCell
          key="loading3"
          {...{
            xs: widths.xs || 1 / 2,
            sm: widths.sm,
            md: widths.sm || 1 / 3,
            lg: widths.lg || 1 / 3,
            xl: widths.xl || 1 / 3
          }}
        >
          <LoadingCard />
        </GridCell>
      </Grid>
    );
  }

  if (!showHasNoResults && products.length === 0) {
    return null;
  }

  return products.length > 0 ? (
    <>
      <Below breakpoint="sxl">
        {productGridText && (
          <GridCell
            key="empty-product-card"
            {...{
              xs: widths.xs || 1 / 2,
              sm: widths.sm,
              md: widths.sm || 1 / 3,
              lg: widths.lg || 1 / 3,
              xl: widths.xl || 1 / 3
            }}
          >
            <EmptyProductCard
              productGridText={productGridText}
              categoryTotalResults={categoryTotalResults}
            />
          </GridCell>
        )}
      </Below>
      <Grid
        data-testid="product-grid"
        className={cx('product-grid', className, priceStyle)}
      >
        <Above breakpoint="sxl">
          {productGridText && (
            <GridCell
              key="empty-product-card"
              {...{
                xs: widths.xs || 1 / 2,
                sm: widths.sm,
                md: widths.sm || 1 / 3,
                lg: widths.lg || 1 / 3,
                xl: widths.xl || 1 / 3
              }}
            >
              <EmptyProductCard productGridText={productGridText} />
            </GridCell>
          )}
        </Above>
        {products?.map((product, index) => {
          return (
            <GridCell
              key={index + ':' + product.articleNumber}
              {...{
                xs: widths.xs || 1 / 2,
                sm: widths.sm,
                md: widths.sm || 1 / 3,
                lg: widths.lg || 1 / 3,
                xl: widths.xl || 1 / 3
              }}
            >
              <ProductCard
                key={index + ':' + product.articleNumber}
                product={product}
                style={{ opacity: loading ? 0.5 : 1 }}
                list={listName}
                categoryPath={categoryPath}
                loadImageEagerly={isAboveFold(index)}
                {...rest}
              />
            </GridCell>
          );
        })}
      </Grid>
    </>
  ) : (
    <ErrorMessageHandler />
  );
}

export default ProductGrid;
