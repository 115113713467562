import React, { useEffect } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import Article from './Article';
import { Carousel, CarouselItem } from '../../../components/ui/Carousel';

export function isBeforeScroll(index) {
  return index < 4;
}

export function ProductCarousel({ articles }) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!articles || articles.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ articles }));
  }, [articles, track]);

  if (!articles || articles.length === 0) return;

  return (
    <Carousel data-testid="product-carousel">
      {articles.map(article => {
        return (
          <CarouselItem key={article.articleNumber}>
            <Article {...article} />
          </CarouselItem>
        );
      })}
    </Carousel>
  );
}

export default ProductCarousel;
