import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../Theme';
import { TrendLink } from './Button';

const CampaignContainer = styled('div')`
  width: 100%;
  height: 100%;
  font-family: 'Barlow';
  position: relative;
`;

const ImageContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TextContainer = styled('div')`
  position: relative;
  z-index: 1;
  color: ${theme.colors.white};
  padding: 80px 40px;
  width: ${props => (props.columns === 2 ? '70%' : '45%')};

  ${theme.below.xl} {
    width: ${props => (props.columns === 2 ? '100%' : '60%')};
  }

  ${theme.below.lg} {
    padding-top: ${props => (props.columns === 2 ? '40px' : '80px')};
    padding-bottom: ${props => (props.columns === 2 ? '40px' : '80px')};
  }

  ${theme.below.md} {
    width: 100%;
    padding: 80px 2rem;
  }

  ${theme.below.sm} {
    padding: 80px 1rem;
  }
`;

const AboveText = styled('div')`
  background-color: #3e4c69;
  width: fit-content;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 32px;
  line-height: 100%;
  margin-bottom: 20px;

  ${theme.below.xl} {
    font-size: 16px;
  }

  ${theme.below.md} {
    font-size: 24px;
  }
`;

const Title = styled('h2')`
  font-weight: 800;
  font-size: 60px;
  line-height: 110%;
  margin-bottom: 20px;
  max-width: 90%;

  ${theme.below.xl} {
    font-size: 36px;
    line-height: 39px;
  }
`;

const InfoText = styled('div')`
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 40px;

  ${theme.below.xl} {
    font-size: 16px;
  }
`;

const UiLink = styled(TrendLink)`
  height: 52px;
`;

const Campaign = ({
  linkUnique,
  linkLabel,
  backgroundImage,
  aboveText,
  titleText,
  infoText,
  children,
  hideLink,
  ...rest
}) => {
  const showLink = !!(linkLabel && linkUnique);

  return (
    <CampaignContainer>
      <TextContainer {...rest}>
        {aboveText && <AboveText>{aboveText}</AboveText>}
        <div>
          {titleText && <Title>{titleText}</Title>}
          {infoText && <InfoText>{infoText}</InfoText>}
        </div>
        {children}
        {!hideLink && showLink && (
          <UiLink appearance="primary" to={linkUnique}>
            {linkLabel.toUpperCase()}
          </UiLink>
        )}
      </TextContainer>
      {backgroundImage && (
        <ImageContainer>
          <Image
            alt=""
            aspect="1:1"
            fillAvailableSpace={true}
            src={backgroundImage}
          />
        </ImageContainer>
      )}
    </CampaignContainer>
  );
};

export default Campaign;
