import React from 'react';

import ContentLoader from 'react-content-loader';

export const LoadingCard = props => (
  <ContentLoader
    speed={2}
    width={'100%'}
    height={460}
    viewBox="0 0 400 460"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="1" rx="2" ry="2" width="140" height="10" />
    <rect x="0" y="25" rx="2" ry="2" width="147" height="11" />
    <rect x="0" y="52" rx="2" ry="2" width="400" height="200" />
    <rect x="0" y="271" rx="0" ry="0" width="270" height="23" />
    <rect x="0" y="390" rx="0" ry="0" width="270" height="23" />
    <rect x="0" y="421" rx="0" ry="0" width="270" height="23" />
    <rect x="0" y="300" rx="0" ry="0" width="270" height="23" />
    <rect x="0" y="329" rx="0" ry="0" width="270" height="23" />
    <rect x="0" y="359" rx="0" ry="0" width="270" height="23" />
  </ContentLoader>
);
