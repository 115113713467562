import { useRangeFilter } from '@jetshop/core/hooks/Filters/useRangeFilter';
import t from '@jetshop/intl';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import ReactSlider from 'react-slider';
import { theme } from '../../Theme';
import RangeInput from '../../ui/RangeInput';

export const RangeSliderContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${theme.below.lg} {
    padding: 1rem;
  }

  .slider {
    height: 1rem;
  }

  .slider.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .track {
    height: 1rem;
    background: transparent;
    padding-bottom: 1rem;

    &:after {
      content: '';
      position: absolute;
      top: 7px;
      height: 2px;
      width: 100%;
      background: #c4c5c5;
    }

    &-1:after {
      background: ${theme.colors.abdGreen};
      height: 3px;
    }
  }

  .thumb {
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    background: ${theme.colors.abdGreen};
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    &:active,
    &:focus {
      outline-color: ${theme.colors.black};
    }

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const ActiveValues = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
`;

const RangeTitle = styled.div`
  text-transform: uppercase;
  font-family: ${theme.fonts.primary};
  font-weight: 700;
  letter-spacing: 0.05em;
`;

export function RangeFilters({ filters }) {
  const rangeFilters = filters.filter(
    filter => filter.__typename === 'NumericRangeFilter'
  );

  if (rangeFilters.length === 0) return null;

  return rangeFilters.map(filter => (
    <ErrorBoundary component={PassThrough} key={filter.id}>
      <RangeSlider filter={filter} />
    </ErrorBoundary>
  ));
}

export function RangeSlider({ filter }) {
  const { apply } = useRangeFilter({ filter });
  // Filter.value may be null (on first render). If it is, use the filter min/max as initial value
  const [initialMin, initialMax] = filter.value
    ? filter.value
    : [filter.min, filter.max];

  // Make sure the selected min/max are never lower or higher than
  // the available min/max respectively.
  const selectedVals = [
    Math.max(initialMin, filter.min),
    Math.min(initialMax, filter.max)
  ];
  // draftVal will be used to track the values from the slider
  const [draftVal, setDraftVal] = useState({
    min: selectedVals[0],
    max: selectedVals[1]
  });

  const handleUpdate = ([min, max]) => {
    setDraftVal({ min, max });
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      apply(draftVal);
    }, 1000);

    return () => clearTimeout(debounce);
  }, [draftVal, apply]);

  return (
    <>
      <RangeSliderContainer>
        <RangeTitle>{t('Price')}</RangeTitle>
        <ReactSlider
          disabled={filter.min === filter.max}
          min={filter.min}
          max={filter.max}
          minDistance={10}
          defaultValue={selectedVals}
          onChange={handleUpdate}
          value={[draftVal.min, draftVal.max]}
          withBars
        />
        <ActiveValues>
          <RangeInput
            type="number"
            value={draftVal.min}
            onChange={e => {
              Number(e.target.value) < draftVal.max &&
                setDraftVal({ min: Number(e.target.value), max: draftVal.max });
            }}
            suffix={t('kr')}
            label={t('From')}
          />
          <RangeInput
            type="number"
            value={draftVal.max}
            onChange={e => {
              Number(e.target.value) > draftVal.min &&
                setDraftVal({ min: draftVal.min, max: Number(e.target.value) });
            }}
            suffix={t('kr')}
            label={t('To')}
          />
        </ActiveValues>
      </RangeSliderContainer>
    </>
  );
}
