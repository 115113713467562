import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

export const CarouselItem = styled.div`
  flex-basis: 228px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: ${({ align }) => align || 'start'};
  flex-direction: column;
  scroll-snap-align: center;
`;
const CarouselInnerWrapper = styled.div`
  --carousel-item-width: ${({ full }) => (full ? '100%' : '300px')};
  padding: 0 var(--layout-gutter, 1rem);
  max-width: 100%;
  gap: ${({ gap }) => (gap && theme.gap[gap]) || theme.gap.medium};
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  overflow: auto;
`;
const CarouselWrapper = styled.div`
  position: relative;
`;

export const Carousel = ({ children }) => {
  const bubbleEvent = e => {
    // Trigger a custom scroll event to allow scroll listeners on window to pickup scroll events withing the document too
    const event = new CustomEvent('scroll', { bubbles: true });
    e.target.parentNode.dispatchEvent(event);
  };

  return (
    <CarouselWrapper>
      <CarouselInnerWrapper onScroll={bubbleEvent}>
        {children}
      </CarouselInnerWrapper>
    </CarouselWrapper>
  );
};
