import React from 'react';
import MaxWidth from '../../../components/Layout/MaxWidth';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import SmallCampaignGrid from './SmallCampaignGrid';
import useChildren from '../../../hooks/useChildren';

const SmallCampaignBlockWrapper = styled('div')`
  background-color: ${theme.colors.white};
`;

const SmallCampaignBlockContainer = styled('div')`
  padding: 40px 0;
`;

const StyledMaxWidth = styled(MaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;

const SmallCampaignBlock = props => {
  const { smallCampaign } = useChildren(props);

  if (!Array.isArray(smallCampaign) || !smallCampaign?.length > 0) return null;

  const hasRoute = category => category.linkUnique;
  const categories = smallCampaign.filter(hasRoute);

  return (
    <SmallCampaignBlockWrapper>
      <StyledMaxWidth>
        <SmallCampaignBlockContainer>
          <SmallCampaignGrid categories={categories} />
        </SmallCampaignBlockContainer>
      </StyledMaxWidth>
    </SmallCampaignBlockWrapper>
  );
};

export default SmallCampaignBlock;
