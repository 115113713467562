import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';
import React from 'react';
import Select from '../../ui/Select';

export const ListFilters = ({ filters }) => {
  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter' && filter.name !== 'carCode'
  );

  if (listFilters.length === 0) return null;

  return listFilters.map(filter => (
    <ErrorBoundary component={PassThrough} key={filter.id}>
      <ListFilter filter={filter} />
    </ErrorBoundary>
  ));
};

export const ListFilter = ({ filter }) => {
  const { apply, clear } = useListFilter({ filter });

  const options = filter.items
    .filter(item => item.resultCount > 0)
    .map(item => ({
      ...item,
      label: `${item.text}`
    }));

  return (
    <Select
      instanceId={filter.id}
      placeholder={filter.name}
      options={options}
      value={options.find(item => item.isActive) || ''}
      onChange={item => {
        if (item.resultCount > 0) {
          clear();
          apply({ value: item.value });
        }
      }}
    />
  );
};
