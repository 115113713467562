import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';
import RegNr from '../../elements/root/RegNrBlock/RegNr';
import MainCategory from '../../elements/root/CategoryBlock/MainCategory';
import InfoBlock from '../../elements/root/InfoBlock/InfoBlock';
import CampaignBlock from '../../elements/root/CampaignBlock/CampaignBlock';
import SubscribeBlock from '../../elements/root/SubscribeBlock/SubscribeBlock';
import SmallCampaignBlock from '../../elements/root/SmallCampaignBlock/SmallCampaignBlock';
import ArticleBlock from '../../elements/root/ArticleBlock/ArticleBlock';
import VideoBlock from '../../elements/root/VideoBlock/VideoBlock';
import ProductGridBlock from '../../elements/root/ProductGridBlock/ProductGridBlock';
import ContentBlock from '../ContentPage/ContentBlock';

const startPageComponents = {
  HEROBLOCK: RegNr,
  CATEGORYBLOCK: MainCategory,
  SMALLCATEGORY: 'smallCategory',
  INFOBLOCK: InfoBlock,
  PRODUCTCOMPONENT: 'productComponent',
  PRODUCTGRIDBLOCK: ProductGridBlock,
  CAMPAIGNBLOCK: CampaignBlock,
  SUBSCRIBEBLOCK: SubscribeBlock,
  SMALLCAMPAIGNBLOCK: SmallCampaignBlock,
  SMALLCAMPAIGN: 'smallCampaign',
  ARTICLEBLOCK: ArticleBlock,
  // CONTENTBLOCK: ContentBlock,
  SMALLARTICLE: 'smallArticle',
  VIDEOBLOCK: VideoBlock
};

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId }
  });
  const { loading } = result;

  if (loading || !result?.data) return <LoadingPage />;

  return (
    <StartPageWrapper>
      <StartPageRenderer
        result={result}
        startPageComponents={startPageComponents}
        LoadingPage={LoadingPage}
      />
    </StartPageWrapper>
  );
};

export default StartPage;
