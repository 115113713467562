import React from 'react';
import ReactSelect from 'react-select';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const inputStyle = {
  fontSize: '14px',
  textTransform: 'uppercase',
  fontFamily: theme.fonts.primary,
  fontWeight: 700,
  color: theme.colors.black,
  letterSpacing: '0.05em'
};

const colorStyles = (extendedStyles = {}) => ({
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: theme.colors.white,
    borderRadius: '0',
    border: `1px solid theme.colors.black`,
    minHeight: '44px',
    width: '350px',
    opacity: isDisabled ? 0.8 : 1,
    maxWidth: '100%',
    boxShadow: 'none',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    '&:active, &:focus, &:hover': {
      borderColor: 'inherit'
    },
    ...extendedStyles.control,
    color: isDisabled ? theme.colors.grey : theme.colors.black
  }),
  container: styles => ({
    ...styles,
    ...extendedStyles.container
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: '1rem'
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    ...inputStyle,
    color: isDisabled ? theme.colors.grey : theme.colors.black
  }),
  singleValue: styles => ({
    ...styles,
    ...inputStyle
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 0,
    marginTop: 0,
    border: `1px solid ${theme.colors.black}`,
    borderTop: 'none',
    zIndex: 9999
  }),
  menuList: styles => ({
    ...styles,
    padding: 0
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? theme.colors.grey : theme.colors.black
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? theme.colors.black : 'inherit',
    color: isSelected ? theme.colors.white : 'inherit',
    paddingLeft: '1rem',
    '&:hover': {
      backgroundColor: isSelected
        ? theme.colors.black
        : theme.colors.abdMediumGrey
    }
  })
});

const StyledCustomOption = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props =>
    props.isSelected ? theme.colors.black : 'inherit'};
  color: ${props => (props.isSelected ? theme.colors.white : 'inherit')};
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.isSelected ? theme.colors.black : theme.colors.abdMediumGrey};
  }
`;

const CustomOption = ({ data, isSelected, innerProps }) => (
  <StyledCustomOption isSelected={isSelected} {...innerProps}>
    <div>{data.label}</div>
    {data.resultCount && <div>({data.resultCount})</div>}
  </StyledCustomOption>
);

const Select = props => {
  return (
    <ReactSelect
      styles={colorStyles(props.extendedStyles)}
      components={{ Option: CustomOption }}
      {...props}
    />
  );
};

export default Select;
