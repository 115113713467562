import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { ReactComponent as Divider } from '../../../assets/svg/Divider.svg';
import MaxWidth from '../../../components/Layout/MaxWidth';
import Image from '@jetshop/ui/Image/Image';
import useCleanProps from '../../../hooks/useCleanProps';

const InfoBlockWrapper = styled('div')`
  background-color: ${theme.colors.white};
`;

const InfoBlockContainer = styled('div')`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 68px;
  font-family: 'Barlow';
  font-style: normal;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const ImgContainer = styled('div')`
  width: 48%;
  height: 500px;
`;

const TextWrapper = styled('div')`
  height: auto;
  width: 48%;

  ${theme.below.md} {
    width: 100%;
  }
`;

const AboveText = styled('div')`
  font-weight: 800;
  font-size: 20px;
  line-height: 115%;
  color: ${theme.colors.adbAboveGrey};
  padding-bottom: 3px;

  ${theme.below.lg} {
    font-size: 18px;
  }

  ${theme.below.md} {
    font-size: 16px;
    line-height: 18.4px;
  }
  ${theme.below.sm} {
    color: ${theme.colors.abdTextGrey};
    padding-bottom: 8px;
  }
`;

const Title = styled('h2')`
  font-weight: 800;
  font-size: 64px;
  line-height: 110%;
  color: ${theme.colors.abdDarkGrey};
  padding-bottom: 24px;
  width: 80%;

  ${theme.below.xl} {
    font-size: 50px;
  }

  ${theme.below.lg} {
    font-size: 40px;
  }

  ${theme.below.md} {
    font-size: 32px;
    line-height: 30.8px;
    width: 100%;
  }
`;

const InfoText = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  color: ${theme.colors.abdDarkGrey};
  padding-bottom: 24px;
  width: 80%;

  ${theme.below.md} {
    font-size: 14px;
    line-height: 21.7px;
    width: 100%;
  }

  ${theme.below.sm} {
    font-size: 16px;
    line-height: 24.8px;
  }
`;

const NumberWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: flex-start;
  flex-direction: row;
  gap: 48px;

  ${theme.below.xl} {
    gap: 30px;
  }

  ${theme.below.lg} {
    gap: 20px;
  }

  ${theme.below.md} {
    gap: 20px;
  }
  ${theme.below.md} {
    gap: 12px;
  }
`;

const ContentContainer = styled('div')``;

const TopContent = styled('div')`
  font-weight: 700;
  font-size: 36px;
  line-height: 115%;
  color: ${theme.colors.abdGreen};

  ${theme.below.xl} {
    font-size: 32px;
  }

  ${theme.below.md} {
    font-size: 28px;
    line-height: 32.2px;
  }
`;

const BelowContent = styled('div')`
  font-weight: 500;
  font-size: 20px;
  line-height: 115%;
  color: ${theme.colors.abdTextGrey};

  ${theme.below.lg} {
    font-size: 18px;
  }

  ${theme.below.md} {
    font-size: 16px;
    line-height: 18.4px;
  }
`;

const Icon = styled('div')``;

const InfoBlock = props => {
  const {
    backgroundImage,
    titleText,
    descriptionText,
    aboveText,
    leftSectionTopContent,
    leftSectionBelowContent,
    centerSectionTopContent,
    centerSectionBelowContent,
    rightSectionTopContent,
    rightSectionBelowContent
  } = useCleanProps(props);
  return (
    <InfoBlockWrapper>
      <MaxWidth>
        <InfoBlockContainer>
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <ImgContainer>
                  <Image
                    alt=""
                    aspect="1:1"
                    fillAvailableSpace={true}
                    src={backgroundImage}
                  />
                </ImgContainer>
              ) : null
            }
          </Above>
          <TextWrapper>
            <AboveText>{aboveText}</AboveText>
            <Title>{titleText}</Title>
            <InfoText>{descriptionText}</InfoText>
            <NumberWrapper>
              <ContentContainer>
                <TopContent>{leftSectionTopContent}</TopContent>
                <BelowContent>{leftSectionBelowContent}</BelowContent>
              </ContentContainer>
              <Icon>
                <Divider />
              </Icon>
              <ContentContainer>
                <TopContent>{centerSectionTopContent}</TopContent>
                <BelowContent>{centerSectionBelowContent}</BelowContent>
              </ContentContainer>
              <Icon>
                <Divider />
              </Icon>
              <ContentContainer>
                <TopContent>{rightSectionTopContent}</TopContent>
                <BelowContent>{rightSectionBelowContent}</BelowContent>
              </ContentContainer>
            </NumberWrapper>
          </TextWrapper>
        </InfoBlockContainer>
      </MaxWidth>
    </InfoBlockWrapper>
  );
};

export default InfoBlock;
