import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useIntl } from '@jetshop/intl';
import { useHistory, useLocation } from 'react-router';
import { theme } from '../../Theme';
import { getFilterQueryString } from '../../../helpers';
import useSearchForm from '../../../hooks/useSearchForm';
import Styled from '../SearchFieldOriginalParts/StyleAlt';
import { ReactComponent as SEicon } from '../../../assets/svg/SEicon.svg';
import { ReactComponent as VerificationCheck } from '../../../assets/svg/VerificationCheck.svg';
import { ReactComponent as InputError } from '../../../svg/InputError.svg';
import SearchField from '../../SearchPage/SearchField';
import { useMultiListFilter } from '../../../elements/root/RegNrBlock/MultiListFilters';
import SearchFieldOriginalParts from '../SearchFieldOriginalParts/SearchFieldOriginalParts';
import MaxWidth from '../../Layout/MaxWidth';
import CarModelSpecification from '../../CategoryPage/CarModelSpecification';

const Section = styled(MaxWidth)`
  margin-top: 2rem;
  ${theme.below.md} {
    margin-top: 1rem;
  }
`;

const OuterContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  max-width: 1024px;

  ${theme.below.md} {
    gap: 1rem;
  }
  ${theme.below.lg} {
    flex-wrap: wrap;
    flex-direction: column;
  }
  ${theme.below.xl} {
    flex-wrap: wrap;
  }
`;

const Form = styled('form')`
  width: 100%;
`;

const RegNrContainer = styled.div`
  width: 100%;
  display: flex;
  > button {
    flex: 1 0 auto;
  }
`;

const SearchFieldContainer = styled('div')`
  width: 100%;
  display: flex;
  > div {
    flex: 1 0 auto;
  }
`;
const ErrorMessageContainer = styled('p')`
  color: red;
`;

const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const SearchButton = styled('button')`
  background-color: #6cc400;
  color: white;
  border: 0;
  outline: none;
  padding: 13px 20px 14px 20px;
  height: 50px;
  font-weight: bold;
  text-transform: uppercase;

  :hover,
  :active {
    background-color: ${theme.colors.abdGreenHover} !important;
  }

  svg:hover {
    path {
      fill: ${theme.colors.abdGreen} !important;
    }
  }
`;

const verificationCheckIcon = css`
  width: 40px;
`;

const inputErrorIcon = css`
  width: 40px;
  transform: rotate(-45deg);
`;

const Label = styled('label')`
  margin-bottom: 0.5rem;
  display: block;
  font-weight: 600;
`;

const SearchRegNrOrProduct = ({
  carData,
  recoSearchString,
  setSearchString,
  oldRecoValue,
  handleSearchStringChange,
  recopartCarModel,
  loading,
  error,
  category
}) => {
  const history = useHistory();
  const location = useLocation();
  const t = useIntl();

  const [searchTerm, setSearchTerm] = useState('');
  const { sendFilterQuery } = useMultiListFilter();

  const categoryId = category?.id;
  const searchForm = useSearchForm(searchTerm, false, categoryId);
  const categoryIdFilters = categoryId ? `${categoryId}:fits-car` : 'fits-car';

  const onSubmitSearch = () => {
    searchForm.triggerSearch(
      recopartCarModel?.carCodeGroupName,
      recopartCarModel?.carCodeName
    );
  };

  const onSubmitLookupRegNr = () => {
    const filter =
      recopartCarModel?.carCodeGroupName || recopartCarModel?.carCodeName
        ? getFilterQueryString(
            categoryId,
            recopartCarModel.carCodeGroupName,
            recopartCarModel.carCodeName
          )
        : location.search;
    let pathname = `/bil?${filter}`;

    if (location.search.includes('?term=')) {
      pathname = `${location.pathname}${
        location.search.split('&')[0]
      }&${filter}`;
    }

    history.push(pathname);
    window.location.reload();
  };

  useEffect(() => {
    if (recopartCarModel && recopartCarModel?.licensePlateNumber) {
      sendFilterQuery({
        multiListFilters: [
          {
            id: categoryIdFilters,
            values: [
              recopartCarModel.carCodeGroupName,
              recopartCarModel.carCodeName
            ]
          }
        ]
      });
      setSearchString(recopartCarModel.licensePlateNumber);
      onSubmitLookupRegNr();
    }
  }, [recoSearchString, recopartCarModel]);

  useEffect(() => {
    if (carData && oldRecoValue !== carData.licensePlateNumber) {
      sendFilterQuery({
        multiListFilters: [
          {
            id: categoryIdFilters,
            values: [carData.carCodeGroupName, carData.carCodeName]
          }
        ]
      });
      setSearchString(carData.licensePlateNumber);
    }

    if (!carData) {
      setSearchString('');
    }
  }, [carData]);

  return (
    <Section>
      <OuterContainer>
        <Form
          role="search"
          onSubmit={e => {
            e.preventDefault();
            onSubmitLookupRegNr();
          }}
        >
          <Label htmlFor="RegInfoInput">
            {t('Enter regnr to find parts that fit your vehicle')}
          </Label>
          <RegNrContainer>
            <Styled.RegNumber>
              <Styled.RegIcon>
                <SEicon />
              </Styled.RegIcon>
              <Styled.RegNr>{t('ABC123')}</Styled.RegNr>
              <Styled.RegInfoInput
                type="search"
                name="RegInfoInput"
                id="RegInfoInput"
                value={recoSearchString}
                onChange={handleSearchStringChange}
                placeholder={t('Enter regnr')}
                aria-label={t(
                  'Enter regnr to find parts that fit your vehicle'
                )}
              />
              {recopartCarModel?.nameOfCar && (
                <VerificationCheck className={verificationCheckIcon} />
              )}
              {error && <InputError className={inputErrorIcon} />}
              {loading && <Styled.Spinner />}
            </Styled.RegNumber>
          </RegNrContainer>
          {error && (
            <ErrorMessageContainer>
              {recopartCarModel?.errorMessage ||
                t('We could not find any car model')}
            </ErrorMessageContainer>
          )}
          <CarModelSpecification carData={carData} />
        </Form>
        <Form
          role="search"
          onSubmit={e => {
            e.preventDefault();
            onSubmitSearch();
          }}
        >
          <Label htmlFor="searchField">
            {t('Or search with originalnumber')}
          </Label>
          <SearchFieldContainer>
            <SearchFieldOriginalParts
              searchField={searchForm}
              searchInputPlaceholder="Bildel/Artnr/Originalnr"
              setSearchTerm={setSearchTerm}
              carData={recopartCarModel}
            />
            <SearchButton disabled={searchForm.loading || !searchForm.term}>
              {t('Search')}
            </SearchButton>
          </SearchFieldContainer>
        </Form>
      </OuterContainer>
    </Section>
  );
};

SearchField.Cancel = CancelButton;

export default SearchRegNrOrProduct;
