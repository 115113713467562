import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../components/Theme';
import { ReactComponent as ArrowRight } from '../../assets/svg/ArrowRight.svg';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';

const ArticleContainer = styled(Link)`
  text-decoration: none;

  :link,
  :visited {
    color: inherit;
  }

  :hover,
  :active {
    color: inherit;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 3px 8px;
  }

  width: 450px;
  max-height: 468px;
  border: 1px solid #dfdfdf;

  ${theme.below.xl} {
    width: 389px;
  }
  ${theme.below.lg} {
    width: 48%;
  }
  ${theme.below.md} {
    width: 300px;
  }
`;

const ImgContainer = styled('div')`
  width: auto;
  height: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Icon = styled('div')`
  -webkit-background-size: cover;
  background-size: cover;

  height: 100%;
  width: 100%;
  display: block;
`;

const ArticleInfoWrapper = styled('div')`
  padding: 10px;
  max-height: 248px;
  position: relative;
`;

const NavLink = styled('div')`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
`;

const Content = ({ linkLabelAlt, linkUrlAlt, backgroundImage }) => {
  return (
    <ArticleContainer to={linkUrlAlt}>
      <ImgContainer>
        <Icon>
          <Image aspect="2:1" cover src={backgroundImage} />
        </Icon>
      </ImgContainer>
      <ArticleInfoWrapper>
        <NavLink>
          {linkLabelAlt}
          <ArrowRight />
        </NavLink>
      </ArticleInfoWrapper>
    </ArticleContainer>
  );
};

export default Content;
