import { useState, useEffect } from 'react';

let timer;

const debounce = (fn, delay) => {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, 500);

    window.addEventListener('resize', debouncedHandleResize);
    debouncedHandleResize();

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
