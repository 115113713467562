import { useState, useRef, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useLazyQuery } from 'react-apollo';
import { useLocation, useHistory } from 'react-router-dom';
import { callAllEventHandlers } from '@jetshop/ui/utils/callAllEventHandlers';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { getFilterQueryString } from '../helpers';
import AutocompleteQuery from '../components/Layout/Header/AutocompleteQuery.gql';

const useSearchForm = (
  initialValue,
  focusOnLoad = false,
  categoryId = null
) => {
  const inputRef = useRef(null);
  const flyoutRef = useRef(null);
  const [isDirty, setIsDirty] = useState(false);
  const [term, setTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const environment = typeof window === 'undefined' ? null : window;
  const location = useLocation();
  const history = useHistory();
  const { routes } = useShopConfig();

  const debouncedUpdateTerm = debounce(term => setDebouncedTerm(term), 200);

  useEffect(() => {
    if (initialValue) {
      setTerm(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    const targetIsInside = target =>
      [flyoutRef, inputRef].some(
        node =>
          node &&
          node.current &&
          (node.current === target || node.current.contains(target))
      );

    const onMouseUp = event => {
      if (!targetIsInside(event.target)) {
        handleBlur(event);
      }
    };

    const onTouchStart = event => {
      if (!targetIsInside(event.target)) {
        handleBlur(event);
        setIsOpen(false);
      }
    };

    if (environment) {
      environment.addEventListener('mouseup', onMouseUp);
      environment.addEventListener('touchstart', onTouchStart);
    }

    if (focusOnLoad) {
      updateFocus('focus');
    }

    return () => {
      if (environment) {
        environment.removeEventListener('mouseup', onMouseUp);
        environment.removeEventListener('touchstart', onTouchStart);
      }
    };
  }, [environment, focusOnLoad]);

  const getInputProps = ({
    onBlur,
    onFocus,
    onChange,
    refKey = 'ref',
    ...rest
  } = {}) => {
    return {
      onChange: event => {
        updateSearch(event);
        onChange && onChange(event);
      },
      onBlur: callAllEventHandlers(onBlur, handleBlur),
      onFocus: callAllEventHandlers(onFocus, handleFocus),
      onKeyDown: callAllEventHandlers(handleEnterSubmit),
      [refKey]: inputRef,
      autoComplete: 'off',
      value: term,
      type: 'search',
      ...rest
      // TODO: add ARIA labels
    };
  };

  const getFlyoutProps = ({ refKey = 'ref', rest } = {}) => {
    return {
      [refKey]: flyoutRef,
      ...rest
    };
  };

  const resetIfEmpty = e => {
    if (e.currentTarget.value === '') {
      clearSearch();
    }
  };

  const handleBlur = (e, callback) => {
    resetIfEmpty(e);
    callback && typeof callback === 'function' && callback();
    return true;
  };

  const handleEnterSubmit = e => {
    if (e.key === 'Enter') {
      triggerSearch();
    }
  };

  const triggerSearch = (manufacturer = null, model = null) => {
    let carModelFilterString = '';
    if (manufacturer && model) {
      const carModelFilter = getFilterQueryString(
        categoryId,
        manufacturer,
        model
      );
      carModelFilterString = carModelFilter ? `&${carModelFilter}` : '';
    }
    const path = routes.search.path;
    const replacer = (_, __, p2) => (p2 ? p2 : '');
    const { search } = location;
    const filterQueryString = search
      .replace('?', '&')
      .replace(/(&term=.+?)(&.+)|(&term=.+)/, replacer);

    setIsOpen(false);
    debouncedUpdateTerm.cancel();

    history.push(
      `${path}?term=${term}${
        filterQueryString.includes('term') ? '' : filterQueryString
      }${carModelFilterString}`
    );
    updateFocus('blur');
  };

  const handleFocus = (_e, callback) => {
    setIsOpen(true);
    callback && typeof callback === 'function' && callback();
    return true;
  };

  const updateFocus = focusState => {
    if (!['blur', 'focus'].includes(focusState)) {
      throw new Error(
        "updateFocus must be called with one of 'blur' | 'focus'"
      );
    }

    const shouldHaveFocus = focusState === 'focus';
    const DOMElementHasFocus = document.activeElement === inputRef.current;

    shouldHaveFocus && !DOMElementHasFocus && inputRef.current.focus();
    !shouldHaveFocus && DOMElementHasFocus && inputRef.current.blur();

    return true;
  };

  const updateSearch = e => {
    const term = e.currentTarget.value;
    const isDirty = term !== '';
    debouncedUpdateTerm(term);
    setTerm(term);
    setIsDirty(isDirty);
    sendQueryAutoComplete();
  };

  const clearSearch = () => {
    setTerm('');
    setIsDirty(false);
    setDebouncedTerm('');
    setIsOpen(false);
  };

  const [sendQuery, { loading, data }] = useLazyQuery(AutocompleteQuery, {
    nextFetchPolicy: 'network-only', // Used for subsequent executions
    context: { useApolloNetworkStatus: false },
    skip: !isDirty || debouncedTerm === initialValue,
    variables: { term: debouncedTerm },
    errorPolicy: 'ignore'
  });

  const sendQueryAutoComplete = () => {
    sendQuery({ variables: { term: debouncedTerm } });
  };

  return {
    getInputProps,
    getFlyoutProps,
    loading: loading || term !== debouncedTerm,
    isOpen,
    updateFocus,
    result: data?.searchAutoComplete,
    inputRef,
    triggerSearch,
    term,
    isDirty
  };
};

export default useSearchForm;
