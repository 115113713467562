import React, { useEffect } from 'react';
import t from '@jetshop/intl';
import { trackPageEvent } from '@jetshop/core/analytics/tracking';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { useLocation } from 'react-router';
import SearchPageBehaviour from './SearchPageBehaviour';
import WindowedSearchResults from './WindowedSearchResults';
import EmptySearchResults from './EmptySearchResults';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import SearchQuery from './SearchQuery.gql';
import { RegNrProvider } from '../../contexts/regnr';

export const Header = styled('header')`
  background: white;
`;

export const Matches = styled('div')`
  text-align: center;
  color: gray;
  padding: 1.5rem 0;
`;

export const Container = styled(MaxWidth)`
  padding: 0 0.75rem;
`;

const renderResults = searchProps => {
  const hasNoResults = searchProps.total === 0;
  return (
    <RegNrProvider>
      <WindowedSearchResults {...searchProps} />
      {hasNoResults && <EmptySearchResults term={searchProps.term} />}
    </RegNrProvider>
  );
};

const SearchPage = () => {
  const track = useTracker();
  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    track(trackPageEvent({ pathname: `${pathname}${search}` }));
  }, [track, pathname, search]);

  return (
    <>
      <SearchPageBehaviour searchQuery={SearchQuery} location={location}>
        {({ error, ...searchProps }) =>
          error ? (
            <div error={error}>{t('An error occured. Please try again!')}</div>
          ) : (
            renderResults(searchProps)
          )
        }
      </SearchPageBehaviour>
    </>
  );
};

export default SearchPage;
