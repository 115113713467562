import { useLocation } from 'react-router';
import qs from 'qs';
import { useQuery } from '@apollo/react-hooks';
import recopartCarModelQuery from '../gql/queries/RecopartCarModelQuery.gql';

export const useCarDataFromLocation = () => {
  const { state, search } = useLocation();

  const params = qs.parse(search, { ignoreQueryPrefix: true });

  const licensePlate =
    typeof window !== 'undefined'
      ? localStorage.getItem('licensePlate')
      : undefined;

  const skip =
    !!licensePlate && params?.multiList
      ? !Object.keys(params.multiList)?.find(p => p.match(/fits-car/))
      : true;

  const licensePlateNumber =
    state?.recopartCarModel?.licensePlateNumber || licensePlate;

  const { data } = useQuery(recopartCarModelQuery, {
    variables: { licensePlateNumber },
    skip
  });

  return state?.recopartCarModel || data?.recopartCarModel;
};
