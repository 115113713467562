import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { useMultiFilter } from '@jetshop/core/hooks/Filters/useMultiFilter';
import { useIntl } from '@jetshop/intl';
import { ReactComponent as Cross } from '../../../assets/svg/cross.svg';
import { MANUFACTURER_SE } from '../../../constants/filters';
import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../../Theme';

export const FilterGroup = styled('div')`
  font-size: 1rem;
`;
const Label = styled('span')`
  font-weight: 300;
  padding-right: 0.25rem;
`;

const Value = styled('span')`
  font-weight: 600;
  padding-right: 1rem;
`;

const StyledCross = styled(Cross)`
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background-color: #646464;
  padding: 2px;
  position: relative;
  top: -2px;
`;

export const AppliedFilter = styled('button')`
  color: ${theme.colors.black};
  border: 1px solid black;
  font-size: 0.875rem;
  background-color: ${theme.colors.white};
  border-radius: 0px;
  height: 26px;
  padding: 0 10px;

  &:hover {
    ${StyledCross} {
      background-color: ${theme.colors.red};
    }
  }
`;

export const FilterWrapper = styled('aside')`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const FilterTitle = styled('div')`
  display: block;
  width: 100%;
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: 500;
`;

export const ActiveFilters = ({ filters }) => {
  const t = useIntl();

  if (!filters) return null;

  const activeMultilistFilters = filters?.filter(
    listFilter => listFilter?.isActive
  );

  const activeListFilters = filters?.filter(listFilter => {
    return listFilter.hasActiveItems && listFilter.name !== 'carCode';
  });

  const hasActiveListFilter = activeListFilters.length > 0;
  const hasActiveMultilistFilter = activeMultilistFilters.length > 0;

  if (!hasActiveListFilter && !hasActiveMultilistFilter) return null;

  return (
    <FilterWrapper>
      <FilterTitle>
        <p>{t('Active filters')}:</p>
      </FilterTitle>
      {hasActiveMultilistFilter &&
        activeMultilistFilters.map(filter => {
          return filter.lists.map(
            (filterItem, index) =>
              filterItem.hasActiveItems && (
                <FilterGroup key={filter.id}>
                  <ActiveMultilistFilterItem
                    item={filterItem}
                    filter={filter}
                    key={filterItem.value}
                    index={index}
                  />
                </FilterGroup>
              )
          );
        })}
      {hasActiveListFilter &&
        activeListFilters.map(filter => {
          return (
            <FilterGroup key={filter.id}>
              {filter.items
                .filter(item => item.isActive)
                .map(filterItem => (
                  <ActiveListFilterItem
                    item={filterItem}
                    filter={filter}
                    key={filterItem.value}
                  />
                ))}
            </FilterGroup>
          );
        })}
    </FilterWrapper>
  );
};

const ActiveListFilterItem = ({ item, filter }) => {
  const { clear } = useListFilter({ filter });

  return (
    <AppliedFilter
      type="button"
      key={item.value}
      onClick={() => {
        clear();
      }}
    >
      <Label>{filter.name} </Label>
      <Value>{item.text}</Value>
      <StyledCross />
    </AppliedFilter>
  );
};

const ActiveMultilistFilterItem = ({ item, filter, index }) => {
  const { clearList } = useMultiFilter({ filter });
  const t = useIntl();
  const activeItem = item.items.find(item => item.isActive);
  return (
    <AppliedFilter
      type="button"
      onClick={() => {
        clearList({ listIndex: index });
      }}
    >
      <Label>
        {item.name === MANUFACTURER_SE ? t('Car brand') : item.name}
      </Label>
      <Value>{activeItem.text}</Value>
      <StyledCross />
    </AppliedFilter>
  );
};
