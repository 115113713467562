import React, { useEffect } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import { Carousel, CarouselItem } from '../../components/ui/Carousel';
import Content from './Content';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Container = styled('div')``;

const Title = styled('p')`
  font-size: 1.3rem;
  font-weight: 700;
  color: #1a202d;

  ${theme.below.md} {
    margin-left: 18px;
  }

  ${theme.below.sm} {
    margin-bottom: 10px;
  }
`;

export function isBeforeScroll(index) {
  return index < 4;
}

export function ContentCarousel({ articles, title }) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!articles || articles.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ articles }));
  }, [articles, track]);

  if (!articles || articles.length === 0) return;

  return (
    <Container>
      <Title>{title?.value?.value}</Title>
      <Carousel data-testid="product-carousel">
        {articles.map(article => {
          return (
            <CarouselItem key={article.articleNumber}>
              <Content {...article} />
            </CarouselItem>
          );
        })}
      </Carousel>
    </Container>
  );
}

export default ContentCarousel;
