import React from 'react';
import ArticleGrid from './ArticleGrid';
import ArticleCarousel from './ArticleCarousel';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import FullWidth from '../../../components/Layout/FullWidth';
import MaxWidth from '../../../components/Layout/MaxWidth';
import useChildren from '../../../hooks/useChildren';

const ArticleBlockWrapper = styled('div')`
  background-color: ${theme.colors.white};
`;

const ArticleBlockContainer = styled('div')`
  color: ${theme.colors.abdDarkGrey};
  height: auto;
  width: 100%;
  font-family: 'Barlow';
  font-style: normal;
  margin-bottom: 40px;
`;

const ArticleBlock = props => {
  const { smallArticle } = useChildren(props);
  if (!Array.isArray(smallArticle) || !smallArticle?.length > 0) return null;

  const hasRoute = article => article.linkUrlAlt || article.linkUrl;
  const articles = smallArticle.filter(hasRoute);

  return (
    <ArticleBlockWrapper>
      <MaxWidth>
        <ArticleBlockContainer>
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <ArticleGrid articles={articles} />
              ) : (
                <FullWidth className="below-xl">
                  <ArticleCarousel articles={articles} />
                </FullWidth>
              )
            }
          </Above>
        </ArticleBlockContainer>
      </MaxWidth>
    </ArticleBlockWrapper>
  );
};

export default ArticleBlock;
