import React, { useEffect } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import { styled } from 'linaria/react';
import { theme } from '../../components/Theme';
import Content from './Content';

const Title = styled('p')`
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a202d;
  margin-bottom: 10px;

  ${theme.below.lg} {
    margin-left: 5px;
  }
`;

const Grid = styled('div')`
  width: 100%;
  color: ${theme.colors.abdDarkGrey};
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: 'Barlow';
  font-style: normal;
  gap: 24px;

  ${theme.below.lg} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ContentGrid = ({ articles, title }) => {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!articles || articles.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ articles }));
  }, [articles, track]);

  if (!articles) return null;

  return (
    <>
      <Title>{title?.value?.value}</Title>
      <Grid>
        {articles.map(article => {
          return <Content {...article} />;
        })}
      </Grid>
    </>
  );
};
export default ContentGrid;
