import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ArrowRight.svg';
import MaxWidth from '../../../components/Layout/MaxWidth';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';
import useChildren from '../../../hooks/useChildren';

const MainCategoryWrapper = styled('div')`
  background-color: ${theme.colors.abdLightGrey};
`;

const CategoryWrapper = styled('div')`
  font-family: 'Barlow';
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-bottom: 40px;

  ${theme.below.lg} {
    gap: 16px;
  }

  ${theme.below.md} {
  }

  ${theme.below.sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

const CategoryContainer = styled(Link)`
  flex: 1 1 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.white};
  -moz-box-shadow: 0 0 5px ${theme.colors.abdMediumGrey};
  -webkit-box-shadow: 0 0 5px ${theme.colors.abdMediumGrey};
  box-shadow: 0 0 5px ${theme.colors.abdMediumGrey};

  ${theme.below.md} {
    width: 189px;
  }
  ${theme.below.sm} {
    min-width: 120px;
    width: 47%;
  }

  text-decoration: none;

  :link,
  :visited {
    color: inherit;
  }

  :hover,
  :active {
    color: inherit;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 3px 8px;
  }
`;

const CategoryInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 40px 0;
`;

const Title = styled('div')`
  font-weight: 800;
  font-size: 24px;
  line-height: 35.52px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
`;

const Discover = styled('div')`
  background-color: ${theme.colors.abdDarkGrey};
  color: ${theme.colors.white};
  width: 100%;
  height: 48px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const ImageWrapper = styled('div')`
  height: 70px;
  width: 70px;
`;

const Category = props => {
  const { smallCategory } = useChildren(props);

  if (!Array.isArray(smallCategory) || !smallCategory?.length > 0) return null;

  const hasRoute = category => category.categoryLink?.primaryRoute?.path;
  const mapCategory = category => ({
    ...category?.categoryLink,
    backgroundImage: category?.backgroundImage,
    categoryTitle: category?.categoryTitle,
    navigationLinkText: category?.categoryLinkText
  });
  const categories = smallCategory.filter(hasRoute).map(mapCategory);

  return (
    <MainCategoryWrapper>
      <MaxWidth>
        <CategoryWrapper>
          {categories.map(
            ({
              key,
              primaryRoute,
              backgroundImage,
              categoryTitle,
              navigationLinkText
            }) => {
              return (
                <CategoryContainer key={key} to={primaryRoute.path}>
                  <CategoryInfoWrapper>
                    {backgroundImage && (
                      <ImageWrapper>
                        <Image src={backgroundImage} />
                      </ImageWrapper>
                    )}

                    {categoryTitle && <Title>{categoryTitle}</Title>}
                  </CategoryInfoWrapper>
                  <Discover>
                    {navigationLinkText}
                    <ArrowRight />
                  </Discover>
                </CategoryContainer>
              );
            }
          )}
        </CategoryWrapper>
      </MaxWidth>
    </MainCategoryWrapper>
  );
};

export default Category;
