import React from 'react';
import { useIntl } from '@jetshop/intl';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { CategoryBrands } from './CategoryBrands';
import { CategoryDescription } from './CategoryDescription';
import SearchRegNrOrProduct from '../../Forms/SearchRegNrOrProduct/SearchRegNrOrProduct';

const CategoryImageWrapper = styled('div')`
  position: relative;
  width: 100%;

  ${theme.below.md} {
    height: 217px;
  }
`;

const Overlay = styled('div')`
  background-color: rgba(0, 0, 0, 0.61);
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const CategoryHeaderWrapper = styled('div')`
  background-color: #ffffff;

  ${Overlay} {
    position: ${props => (props.hasBackgroundImage ? 'absolute' : 'inherit')};
  }

  ${CategoryImageWrapper} {
    height: ${props => (props.hasBackgroundImage ? '416px' : 'auto')};
  }

  ${theme.below.sm} {
    padding: 0rem;
  }
`;

const CategoryHeader = ({
  backgroundImage,
  children,
  description,
  ...rest
}) => {
  const t = useIntl();
  return (
    <CategoryHeaderWrapper hasBackgroundImage={backgroundImage}>
      {!description && (
        <CategoryDescription
          description={{ value: `<h1>${t('Find car part')}</h1>` }}
        />
      )}
      {<CategoryBrands brands={children} />}
      {<CategoryDescription description={description} />}
      <SearchRegNrOrProduct {...rest} />
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
