import { useIntl } from '@jetshop/intl';
import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import searchQuery from './SearchQuery.gql';
import { useApolloClient } from 'react-apollo';
import { getActiveFiltersFromSearch } from '@jetshop/core/resolvers/filters/filterParams';
import qs from 'qs';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { Below, Above } from '@jetshop/ui/Breakpoints';
import { TrendLink } from '../ui/Button';
import { useLocation } from 'react-router';
import useInfinitePagination from '@jetshop/core/hooks/useInfinitePagination';
import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import { theme } from '../Theme';
import ProductGrid from '../CategoryPage/ProductGrid';
import Filters from '../CategoryPage/Filters';
import FilterDrawer from '../CategoryPage/Filters/FilterDrawer';
import FilterButton from '../CategoryPage/Filters/Mobile/FilterButton';
import SearchRegNrOrProduct from '../Forms/SearchRegNrOrProduct/SearchRegNrOrProduct';
import useRecopartSearch from '../../hooks/useRecopartSearch';
import { useCarDataFromLocation } from '../../hooks/useCarDataFromLocation';
import { CategoryDescription } from '../CategoryPage/CategoryHeader/CategoryDescription';
import { useRegNrDispatch } from '../../contexts/regnr';
import { LICENSEPLATE } from '../../constants/localstorage';

const Matches = styled('div')`
  display: flex;
  text-align: center;
  color: gray;
  padding: 1rem 0;
`;

const Wrapper = styled('div')``;

const MainContent = styled.div``;

const Container = styled(MaxWidth)`
  gap: 4rem;
`;

const ButtonWrapper = styled('div')`
  width: 100%;
  max-width: 16rem;
  margin: 1rem auto;
`;

const HeaderContainer = styled('div')`
  max-width: 1024px;
  margin-top: 1rem;
`;

const FilterOptionContainer = styled('div')`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;

  ${theme.below.xl} {
    margin-bottom: 0;
  }
`;

const DrawerButtonContainer = styled('div')`
  display: flex;

  ${theme.below.xl} {
    margin-top: 0rem;
  }
`;

const FilterAndSortContainer = styled('div')`
  ${theme.below.xl} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

function useFiltersOnHistoryPop() {
  const client = useApolloClient();

  useEffect(() => {
    function getSearchFilters() {
      // Write the active filters from search
      client.writeData({
        data: {
          activeFilters: getActiveFiltersFromSearch(window.location.search)
        }
      });
    }

    getSearchFilters();
  }, [client]);
}

const WindowedSearchResults = ({ loading, total, term, result }) => {
  const { currentPage } = React.useContext(PaginationContext);
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const {
    searchString: recoSearchString,
    setSearchString,
    oldValue: oldRecoValue,
    handleSearchStringChange,
    data: recopartCarModel,
    loading: loadingRecoPart,
    error
  } = useRecopartSearch();

  const [carData, setCarData] = useState(null);
  const carInformation = useCarDataFromLocation();
  const t = useIntl();
  const dispatch = useRegNrDispatch();

  useEffect(() => {
    if (carInformation) {
      setCarData(carInformation);
    }
  }, [carInformation]);

  const { products, previous, next } = useInfinitePagination({
    result,
    query: searchQuery
  });

  useFiltersOnHistoryPop();

  const handleClearFilters = () => {
    setCarData(null);
    if (typeof window !== 'undefined') {
      localStorage.removeItem(LICENSEPLATE);
    }
    dispatch({ type: 'resetManufacturer' });
    dispatch({ type: 'resetModel' });
  };

  return (
    <Wrapper>
      <CategoryDescription
        description={{ value: `<h1>${t('Search car part')}</h1>` }}
      />
      <HeaderContainer>
        <SearchRegNrOrProduct
          recoSearchString={recoSearchString}
          setSearchString={setSearchString}
          oldRecoValue={oldRecoValue}
          handleSearchStringChange={handleSearchStringChange}
          recopartCarModel={recopartCarModel}
          loading={loadingRecoPart}
          error={error}
          carData={carData}
        />
      </HeaderContainer>
      <Container>
        <MainContent>
          <Below breakpoint="xl">
            <FilterAndSortContainer>
              <FilterOptionContainer>
                <DrawerButtonContainer>
                  <DrawerTrigger id="filterDrawer">
                    {({ showTarget }) => (
                      <>
                        <FilterButton
                          showTarget={showTarget}
                          filters={result.data?.search?.products?.filters || []}
                        />
                        <DrawerTarget id="filterDrawer">
                          {({ hideTarget, isOpen }) => (
                            <Drawer isOpen={isOpen} right>
                              <FilterDrawer
                                close={hideTarget}
                                filters={
                                  result.data?.search?.products?.filters || []
                                }
                                onCarBrandChange={handleClearFilters}
                                handleClearFilters={handleClearFilters}
                              />
                            </Drawer>
                          )}
                        </DrawerTarget>
                      </>
                    )}
                  </DrawerTrigger>
                </DrawerButtonContainer>
              </FilterOptionContainer>
            </FilterAndSortContainer>
          </Below>
          <Matches>
            {loading ? (
              currentPage === 1 ? (
                <div>
                  {t(`Loading search results for {term}…`, {
                    term
                  })}
                </div>
              ) : (
                <div>
                  {t(`Loading page {currentPage}…`, {
                    currentPage
                  })}
                </div>
              )
            ) : (
              <div>
                {t(
                  '{total, plural, =1 {# item} other {# items}} matching for "{term}"',
                  {
                    total,
                    term
                  }
                )}
              </div>
            )}
          </Matches>
          <Above breakpoint="xl">
            <Filters
              filters={result.data?.search?.products?.filters || []}
              handleClearFilters={handleClearFilters}
            />
          </Above>
          {previous.hasProducts && (
            <ButtonWrapper>
              <TrendLink
                secondary
                onClick={e => {
                  e.preventDefault();
                  previous.fetchProducts();
                }}
                to={{
                  ...location,
                  search: qs.stringify({
                    ...params,
                    page: previous.page
                  })
                }}
              >
                {t('Show more')}
              </TrendLink>
            </ButtonWrapper>
          )}
          <ProductGrid
            id="search"
            prevOffset={previous.offset}
            products={products}
            listName={'search'}
            loading={result.loading}
            imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
            showHasNoResults={false}
          />
          {next.hasProducts && (
            <ButtonWrapper>
              <TrendLink
                secondary
                onClick={e => {
                  e.preventDefault();
                  next.fetchProducts();
                }}
                to={{
                  ...location,
                  search: qs.stringify({
                    ...params,
                    page: next.page
                  })
                }}
              >
                {t('Show more')}
              </TrendLink>
            </ButtonWrapper>
          )}
        </MainContent>
      </Container>
    </Wrapper>
  );
};

export default WindowedSearchResults;
