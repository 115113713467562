import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import useCleanProps from '../../../hooks/useCleanProps';
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription';
import { useIntl } from '@jetshop/intl';
import { css } from 'linaria';
import Button from '@jetshop/ui/Button';

const HeaderBlockWrapper = styled('div')`
  width: 100%;
  height: 124px;
  background-color: ${theme.colors.abdLightGrey};
  color: ${theme.colors.abdDarkGrey};
  font-family: 'Barlow';
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  ${theme.below.xl} {
    flex-direction: column;
    height: 229px;
  }

  ${theme.below.sm} {
    flex-direction: column;
    height: 250px;
  }
`;

const Title = styled('div')`
  font-weight: 600;
  font-size: 24px;
  line-height: 144.5%;
  margin-right: 40px;

  ${theme.below.xl} {
    font-size: 18px;
    line-height: 26.01px;
    margin-bottom: 16px;
  }

  ${theme.below.md} {
    display: flex;
    text-align: center;
    font-size: 16px;
    padding-left: 40px;
    justify-content: center;
    align-items: center;
  }
`;

const Form = styled('form')`
  display: flex;
  flex-direction: row;

  ${theme.below.md} {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const EmailInputField = styled('input')`
  width: 371px;
  height: 52px;
  background-color: ${theme.colors.white};
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  border: 0.5px solid #c4c5c5;
  -moz-box-shadow: 0 0 5px ${theme.colors.abdMediumGrey};
  -webkit-box-shadow: 0 0 5px ${theme.colors.abdMediumGrey};
  box-shadow: 0 0 5px ${theme.colors.abdMediumGrey};

  &.error {
    border-color: ${theme.colors.red};
    width: 371px;
  }

  :focus {
    outline: none;
  }

  ${theme.below.md} {
    font-size: 16px;
    margin-bottom: 16px;
  }

  ${theme.below.md} {
    font-size: 14px;
    width: 335px;
  }
`;

const ErrorMessageHandler = styled('div')`
  display: flex;
  align-items: center;
  top: 100%;
  color: ${theme.colors.red};
  font-size: 0.875rem;
  width: auto;
  text-align: left;
  height: 52px;
  padding-left: 1rem;
`;

const SubscribeButton = styled(Button)`
  width: 240px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-left: 18px;

  background-color: ${theme.colors.abdGreen};
  text-decoration: none;
  &.submitting {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  :hover,
  :active {
    background-color: ${theme.colors.abdGreenHover} !important;
  }

  ${theme.below.md} {
    width: 200px;
  }
`;

const newsletter_subscription_success_message = css`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  padding-top: 2rem;
`;

const SubscribeBlock = props => {
  const t = useIntl();
  const { subscriptionTextLabel } = useCleanProps(props);
  const [completed, setCompleted] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const newsletterProps = useNewsletterSubscription({
    enabled: true
  });

  const {
    inputProps,
    submit,
    submitting,
    submitted,
    failed,
    errorStates: { alreadySubscribed, invalidEmail }
  } = newsletterProps;

  const isError = hasSubmitted || alreadySubscribed || invalidEmail || failed;

  const disabled = inputProps.value.length === 0 || submitting;

  useEffect(() => {
    if (submitted) setCompleted(true);
  }, [submitted, setCompleted]);

  function onSubmit(e) {
    e.preventDefault();
    setHasSubmitted(true);
    return submit(e);
  }

  if (completed)
    return (
      <p
        data-testid="newsletter-subscription-success"
        className={newsletter_subscription_success_message}
      >
        {t('You are now subscribed')}
      </p>
    );

  return (
    <HeaderBlockWrapper>
      <Title>{subscriptionTextLabel}</Title>
      <Form onSubmit={onSubmit} disabled={disabled}>
        <EmailInputField
          type="email"
          id="newsletter"
          data-testid="newsletter-subscription-input"
          placeholder={t('Enter your E-mail address')}
          className={isError ? 'error' : ''}
          {...inputProps}
        />
        <SubscribeButton
          type="submit"
          disabled={disabled}
          data-testid="newsletter-subscription-submit"
          loading={submitting}
          className={submitting ? 'submitting' : ''}
        >
          {submitting ? t('Submitting…') : t('Subscribe')}
        </SubscribeButton>
        {isError && (
          <ErrorMessageHandler>
            {alreadySubscribed
              ? t('You have already subscribed to the newsletter!')
              : invalidEmail || failed
              ? t(
                  'Something went wrong. Please check your email and try again.'
                )
              : null}
          </ErrorMessageHandler>
        )}
      </Form>
    </HeaderBlockWrapper>
  );
};

export default SubscribeBlock;
