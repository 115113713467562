import React from 'react';
import { styled } from 'linaria/react';
import { useIntl } from '@jetshop/intl';
import { theme } from '../../Theme';
import MaxWidth from '../../Layout/MaxWidth';

const BrandsWrapper = styled.div`
  padding: ${theme.space[3]} 0;
  background-color: ${theme.colors.white};

  ${theme.below.sm} {
    padding: ${theme.space[1]} 0;
  }
`;

const LogotypesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 80px;

  ${theme.below.lg} {
    gap: 30px;
  }

  ${theme.below.md} {
    gap: 15px;
  }
`;

const Img = styled.img`
  width: auto;
  height: 35px;
  filter: grayscale(100%);
  transition: filter 0.1s linear;

  ${theme.below.md} {
    height: 28px;
  }

  &:hover {
    filter: grayscale(0);
  }
`;

const BrandsInner = styled(MaxWidth)`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${theme.below.sm} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BrandsTitle = styled.div`
  padding-right: ${theme.space[3]};
  display: flex;
  justify-content: center;
  font-family: ${theme.fonts.primary};
  text-transform: uppercase;
  font-size: ${theme.fontSizes[2]};
  color: ${theme.colors.darkerGrey};
  font-weight: 700;
  flex-shrink: 0;
  letter-spacing: 0.04em;

  &::after {
    content: '';
    display: inline-block;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: ${theme.colors.white};
    width: 2px;
    height: 28px;
    margin-left: ${theme.space[3]};

    ${theme.below.sm} {
      content: none;
    }
  }
`;

export const CategoryBrands = ({ brands }) => {
  const t = useIntl();

  if (!brands) return null;

  return (
    <BrandsWrapper>
      <BrandsInner>
        <BrandsTitle>{t('Brands')}</BrandsTitle>
        <LogotypesWrapper>
          {brands?.map(({ key, props }) => (
            <Img
              key={key}
              src={`${props.logotype?.value?.value}?extend=copy&method=fit&width=200&height=200&type=webp`}
              alt=""
            />
          ))}
        </LogotypesWrapper>
      </BrandsInner>
    </BrandsWrapper>
  );
};
