import React from 'react';
import Styled from './Style';
import { cx } from 'linaria';

export function EmptyProductCard({ productGridText, categoryTotalResults }) {
  return (
    <div className={cx('product-card', Styled.wrapperStyling)}>
      {productGridText &&
        productGridText?.properties?.map((item, index) => (
          <div
            key={index}
            dangerouslySetInnerHTML={{
              __html: item?.value?.value
            }}
            className="empty-product-card"
            style={{ padding: '20px', backgroundColor: '#fff' }}
          ></div>
        ))}
      {/* <p>test{categoryTotalResults}</p> */}
    </div>
  );
}
