import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { ModalTrigger } from '@jetshop/ui/Modal/ModalTrigger';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../Theme';
import ButtonWithLoading from '../ui/Button';

const ModalButton = styled('div')`
  margin-top: 1rem;
`;

const CarModelContentWrapper = styled('div')`
  display: flex;
  gap: 0rem 0.5rem;
  padding: 0.3125rem;
  padding-left: 0;
  align-items: center;
  flex-wrap: wrap;

  ${theme.above.xl} {
    flex-wrap: wrap;
  }

  ${theme.below.sm} {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem 0rem;
    padding: 0;
    padding-left: 0;
    align-items: flex-start;
  }

  & span {
    color: ${theme.colors.grey};
  }
`;

const CarModelContent = styled('p')`
  margin-left: 0;
  color: ${theme.colors.grey};
  font-size: 0.875rem;
  ${theme.below.sm} {
    font-size: 0.875rem;
  }

  & span {
    font-weight: normal;
    ${theme.below.sm} {
      font-weight: bold;
    }
  }
`;

const CloseModalButton = styled(ButtonWithLoading)`
  padding: 0.5rem 1.875rem;
  margin-top: 1rem;
  position: absolute;
  bottom: 1.5rem;
`;

const modalContent = css`
  margin-top: 1.5rem;
`;
const modalHeading = css`
  margin-bottom: 1rem;
`;

const closeIcon = css`
  cursor: pointer;
  font-size: 2rem;
  text-align: right;
  padding: 0.625rem;
  line-height: 0;
`;

const CarModel = ({ carData }) => {
  return (
    <CarModelContentWrapper>
      {carData?.nameOfCar && (
        <CarModelContent>
          {t('Name of car')}: <span>{carData?.nameOfCar}</span>
        </CarModelContent>
      )}
      {carData?.licensePlateNumber && (
        <CarModelContent>
          {t('Licenseplate Number')}: <span>{carData?.licensePlateNumber}</span>
        </CarModelContent>
      )}
      {carData?.modelYear && (
        <CarModelContent>
          {t('Model year')}: <span>{carData?.modelYear}</span>
        </CarModelContent>
      )}
      {carData?.gearboxText && (
        <CarModelContent>
          {t('Gearbox')}: <span>{carData?.gearboxText}</span>
        </CarModelContent>
      )}
      {carData?.aisEngineCode && (
        <CarModelContent>
          {t('Engine code')}: <span>{carData?.aisEngineCode}</span>
        </CarModelContent>
      )}
      {carData?.carType && (
        <CarModelContent>
          {t('Car type')}: <span>{carData?.carType}</span>
        </CarModelContent>
      )}
      {carData?.color && (
        <CarModelContent>
          {t('Color')}: <span>{carData?.color}</span>
        </CarModelContent>
      )}
      {carData?.bodyStyle && (
        <CarModelContent>
          {t('Body style')}: <span>{carData?.bodyStyle}</span>
        </CarModelContent>
      )}
      {carData?.fuelText && (
        <CarModelContent>
          {t('Fuel')}: <span>{carData?.fuelText}</span>
        </CarModelContent>
      )}
    </CarModelContentWrapper>
  );
};

const VehicleInformationModal = ({ hideTarget, carData }) => {
  return (
    <div>
      <p className={closeIcon} onClick={hideTarget}>
        x
      </p>
      <div className={modalContent}>
        <h3 className={modalHeading}>{t('Chosen vehicle')}</h3>
        <CarModel carData={carData} />
        <CloseModalButton onClick={hideTarget}>{t('Close')}</CloseModalButton>
      </div>
    </div>
  );
};

const CarModelSpecification = ({ carData }) => {
  return (
    <>
      {carData ? (
        <>
          <Above breakpoint="sm">
            <CarModel carData={carData} />
          </Above>
          <Below breakpoint="sm">
            <ModalTrigger
              target={({ hideTarget }) => (
                <VehicleInformationModal
                  carData={carData}
                  hideTarget={hideTarget}
                />
              )}
            >
              {({ showTarget }) => (
                <ModalButton>
                  <ButtonWithLoading
                    onClick={showTarget}
                    className="secondary fill-width small"
                    type="button"
                  >
                    {t('See chosen vechicle')}
                  </ButtonWithLoading>
                </ModalButton>
              )}
            </ModalTrigger>
          </Below>
        </>
      ) : (
        <CarModelContentWrapper>
          <p>
            {t('Chosen vehicle')}: <span>{t('None specified')}</span>
          </p>
        </CarModelContentWrapper>
      )}
    </>
  );
};

export default CarModelSpecification;
