import { styled } from 'linaria/react';
import { useLocation } from 'react-router';
import t from '@jetshop/intl';
import { Below } from '@jetshop/ui/Breakpoints';
import useInfinitePagination from '@jetshop/core/hooks/useInfinitePagination';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import React, { useState, useMemo, useEffect } from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import qs from 'qs';
import CategoryHeader from './CategoryHeader/CategoryHeader';
import MaxWidth from '../Layout/MaxWidth';
import CategoryHeaderLoadingState from './CategoryHeader/CategoryHeaderLoadingState';
import routeQuery from '../RouteQuery.gql';
import { TrendLink } from '../ui/Button';
import { theme } from '../Theme';
import Filters from './Filters';
import ProductGrid from './ProductGrid';
import { SortOrders } from './Filters/SortOrders';
import FilterDrawer from './Filters/FilterDrawer';
import FilterButton from './Filters/Mobile/FilterButton';
import { RegNrProvider, useRegNrDispatch } from '../../contexts/regnr';
import { LICENSEPLATE } from '../../constants/localstorage';
import { useCarDataFromLocation } from '../../hooks/useCarDataFromLocation';
import useRecopartSearch from '../../hooks/useRecopartSearch';

const Container = styled(MaxWidth)`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;

  ${theme.below.lg} {
    grid-template-columns: auto;
  }

  ${theme.below.md} {
    padding-top: 0rem;
  }

  ${theme.above.sm} {
    padding-top: 1rem;
  }
`;

const CategoryWrapper = styled('div')``;

const FilterAndSortContainer = styled('div')`
  ${theme.below.xl} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const ButtonWrapper = styled('div')`
  width: 100%;
  max-width: 16rem;
  margin: 1rem auto;
`;

const FilterOptionContainer = styled('div')`
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;

  ${theme.below.xl} {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const DrawerButtonContainer = styled('div')`
  display: flex;

  ${theme.below.xl} {
    margin-top: 0rem;
  }
`;

const MainContent = styled.div``;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-bottom: 0.625rem;
  margin-top: 2rem;
  margin-left: 2rem;

  ${theme.below.xl} {
    margin: 1rem 1rem 0;
  }
`;

const SortOrdersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 0.3125rem;

  ${theme.below.xl} {
    align-items: center;
  }
`;

const Content = styled('div')`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const WindowedCategoryWrapper = props =>
  !props.category ? (
    <CategoryWrapper>
      <CategoryHeaderLoadingState />
    </CategoryWrapper>
  ) : (
    <RegNrProvider>
      <WindowedCategory {...props} />
    </RegNrProvider>
  );

const WindowedCategory = ({ category, result }) => {
  const [carData, setCarData] = useState(null);
  const carInformation = useCarDataFromLocation();
  const dispatch = useRegNrDispatch();
  const {
    searchString: recoSearchString,
    setSearchString,
    oldValue: oldRecoValue,
    handleSearchStringChange,
    data: recopartCarModel,
    loading,
    error
  } = useRecopartSearch();

  useEffect(() => {
    if (carInformation) {
      setCarData(carInformation);
    }
  }, [carInformation]);

  const handleClearFilters = () => {
    setCarData(null);
    if (typeof window !== 'undefined') {
      localStorage.removeItem(LICENSEPLATE);
    }
    dispatch({ type: 'resetManufacturer' });
    dispatch({ type: 'resetModel' });
  };

  const { previous, next, products } = useInfinitePagination({
    result,
    query: routeQuery
  });

  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const routePath = category?.isDynamic ? null : result?.data?.route?.path;
  const categoryPath = useMemo(() => routePath && { path: routePath }, [
    routePath
  ]);

  const renderHeader = !category.data?.items?.find(
    item => item.type === 'CategoryHeader'
  );

  const content = category?.data?.items[1];
  const productGridText = category?.data?.items[2];

  return (
    <CategoryWrapper>
      {category.data && (
        <ContentRenderer
          items={category.data.items}
          components={{
            CATEGORYHEADER: props => (
              <CategoryHeader
                category={category}
                parents={result?.data?.route?.parents}
                {...props}
                breadcrumbs={
                  props.breadcrumbs?.value
                    ? result?.data?.route?.breadcrumbs
                    : []
                }
                carData={carData}
                recopartCarModel={recopartCarModel}
                recoSearchString={recoSearchString}
                setSearchString={setSearchString}
                oldRecoValue={oldRecoValue}
                handleSearchStringChange={handleSearchStringChange}
                loading={loading}
                error={error}
              />
            ),
            CATEGORYBRANDS: 'Category brands'
          }}
        />
      )}
      <>
        {renderHeader && (
          <CategoryHeader
            category={category}
            parents={result?.data?.route?.parents}
            breadcrumbs={result?.data?.route?.breadcrumbs}
            carData={carData}
            recopartCarModel={recopartCarModel}
            recoSearchString={recoSearchString}
            setSearchString={setSearchString}
            handleSearchStringChange={handleSearchStringChange}
            loading={loading}
            error={error}
          />
        )}
        <FilterContainer>
          <Filters
            filters={category.products?.filters || []}
            handleClearFilters={handleClearFilters}
          />
        </FilterContainer>
        <Container>
          <MainContent>
            <FilterAndSortContainer>
              <Below breakpoint="xl">
                {matches =>
                  matches && (
                    <FilterOptionContainer>
                      <DrawerButtonContainer>
                        <DrawerTrigger id="filterDrawer">
                          {({ showTarget }) => (
                            <>
                              <FilterButton
                                showTarget={showTarget}
                                filters={category.products?.filters || []}
                              />
                              <DrawerTarget id="filterDrawer">
                                {({ hideTarget, isOpen }) => (
                                  <Drawer isOpen={isOpen} right>
                                    <FilterDrawer
                                      close={hideTarget}
                                      filters={category.products?.filters || []}
                                      onCarBrandChange={handleClearFilters}
                                    />
                                  </Drawer>
                                )}
                              </DrawerTarget>
                            </>
                          )}
                        </DrawerTrigger>
                      </DrawerButtonContainer>
                    </FilterOptionContainer>
                  )
                }
              </Below>
              <SortOrdersWrapper>
                <SortOrders sortOrders={category.products?.sortOrders || []} />
              </SortOrdersWrapper>
            </FilterAndSortContainer>
            {previous.hasProducts && (
              <ButtonWrapper>
                <TrendLink
                  className="secondary"
                  onClick={e => {
                    e.preventDefault();
                    previous.fetchProducts();
                  }}
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...params,
                      page: previous.page
                    })
                  }}
                >
                  {t('Show more')}
                </TrendLink>
              </ButtonWrapper>
            )}
            <ProductGrid
              id="category"
              products={products}
              listName={category.name}
              categoryPath={categoryPath}
              loading={result.loading}
              imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
              productGridText={productGridText}
              categoryTotalResults={category.products?.totalResults}
            />
            {next.hasProducts && (
              <ButtonWrapper>
                <TrendLink
                  className="secondary"
                  onClick={e => {
                    e.preventDefault();
                    next.fetchProducts();
                  }}
                  to={{
                    ...location,
                    search: qs.stringify({
                      ...params,
                      page: next.page
                    })
                  }}
                >
                  {t('Show more')}
                </TrendLink>
              </ButtonWrapper>
            )}
            {content &&
              content?.properties?.map((item, index) => (
                <Content
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item?.value?.value
                  }}
                />
              ))}
          </MainContent>
        </Container>
      </>
    </CategoryWrapper>
  );
};

export default WindowedCategoryWrapper;
