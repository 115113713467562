import React, { useEffect, useRef, useState } from 'react';
import { theme } from '../../../Theme';
import { styled } from 'linaria/react';

const ButtonWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
`;

const Button = styled('button')`
  position: relative;
  z-index: 9;
  background: black;
  color: white;
  padding: 8px 40px;
  border: none;
  cursor: pointer;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  outline: none;
  font-size: 12px;

  p {
    transition: all 0.2s ease;
    padding-right: 10px;
  }
  &.fixed {
    top: 32px;
  }

  ${theme.below.sm} {
    padding: 12px 20px;
  }
`;

const Badge = styled('div')`
  position: absolute;
  display: block;
  top: 4px;
  right: 22px;
  background: white;
  color: black;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 0.8rem;
  z-index: 2;
  line-height: 0.9rem;
  text-align: center;

  ${theme.below.sm} {
    top: 4px;
    right: 8px;
  }
`;

const IconWrapper = styled('div')`
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  line-height: 0;
`;

const FilterButton = ({ showTarget, filters, showIcon = true }) => {
  const buttonWrapperElem = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    function scroll() {
      const elemYPos = buttonWrapperElem.current.getBoundingClientRect().y;
      if (elemYPos <= 32) setIsFixed(true);
      else setIsFixed(false);
    }
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  });

  let activeFilters = 0;
  filters?.filter(filter => {
    if (filter.isActive && filter.lists) {
      filter.lists.filter(item => {
        if (item.hasActiveItems) {
          activeFilters++;
        }
        return item;
      });
    }

    if (filter.hasActiveItems && filter.name !== 'carCode') {
      activeFilters++;
    }

    return filter;
  });

  return (
    <ButtonWrapper ref={buttonWrapperElem}>
      <Button onClick={showTarget} className={isFixed ? 'fixed' : ''}>
        <IconWrapper>
          {showIcon && activeFilters > 0 && <Badge>{activeFilters}</Badge>}
        </IconWrapper>
        Filteralternativ
      </Button>
    </ButtonWrapper>
  );
};

export default FilterButton;
