import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const ClearButton = styled('button')`
  font-family: ${theme.fonts.primary};
  font-weight: 500;
  font-size: 14px;
  background-color: transparent;
`;

export default ClearButton;
