import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import recopartCarModelQuery from '../gql/queries/RecopartCarModelQuery.gql';
import { LICENSEPLATE } from '../constants/localstorage';

const useRecopartSearch = () => {
  const [searchString, setSearchString] = useState('');
  const [oldValue, setOldValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [recopartCarModel, setRecopartCarModel] = useState(null);
  const [getCarModel, { loading, error, data }] = useLazyQuery(
    recopartCarModelQuery,
    {
      nextFetchPolicy: 'network-only' // Used for subsequent executions
    }
  );
  const history = useHistory();

  const submit = useCallback(() => {
    setOldValue(searchString);
    getCarModel({
      variables: { licensePlateNumber: searchString }
    });
  }, [getCarModel, searchString]);

  const handleSubmit = e => {
    e.preventDefault();
    submit();
  };

  const handleOnKeyDown = e => {
    const searchQuery = qs.stringify(
      { list: { '2:carCodeCode': [data?.recopartCarModel?.carCode] } },
      { encode: false }
    );

    if (e.charCode === 13 || e.key === 'Enter') {
      history.push({
        pathname: `${history.location.pathname || '/bil'}?${searchQuery}`,
        state: {
          recopartCarModel: data?.recopartCarModel
        }
      });
    }
  };

  const shouldUpdateString = string => {
    /** REG NUMBER TEST */
    const test = /(^[A-Ö]{3}[0-9]{3}$|^[A-Ö]{3}[0-9]{2}[A-Ö]?$|^[A-Ö]{3}[0-9]{1,2}$|^[A-Ö]{0,3}$)/g.test(
      string || ''
    );

    return test;
  };

  const handleSearchStringChange = e => {
    const string = e.target.value;
    const shouldUpdate = shouldUpdateString(string);

    if (!shouldUpdate) return null;

    setSearchString(string);
    setDisabled(string.length < 6);
  };

  useEffect(() => {
    setRecopartCarModel(
      data?.recopartCarModel ||
        (!loading && {
          error: true,
          errorMessage: 'Bilen hittades inte'
        })
    );
  }, [data]);

  useEffect(() => {
    if (!loading && !error && !disabled) {
      searchString && submit();
      if (typeof window !== 'undefined') {
        const lisencePlateStorageItem = localStorage.getItem(LICENSEPLATE);
        if (lisencePlateStorageItem !== searchString) {
          localStorage.removeItem(LICENSEPLATE);
          localStorage.setItem(LICENSEPLATE, searchString);
        }
      }
    }
  }, [disabled, loading, error, submit]);

  useEffect(() => {
    if (searchString.length === 0) {
      setRecopartCarModel(null);
    }
  }, [searchString]);

  return {
    searchQuery: qs.stringify(
      {
        multiList: {
          '2:fits-car': [
            data?.recopartCarModel?.carCodeGroupName,
            data?.recopartCarModel?.carCodeName
          ]
        }
      },

      { encode: true }
    ),
    searchString,
    setSearchString,
    oldValue,
    handleSearchStringChange,
    handleSubmit,
    handleOnKeyDown,
    loading,
    data: recopartCarModel,
    disabled,
    error
  };
};

export default useRecopartSearch;
