import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const RangeInputWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledInput = styled.input`
  border: 1px solid #c4c5c5;
  border-radius: 4px;
  height: 28px;
  width: 100px;
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 700;
  padding: 2px 11px;
  color: transparent;
  caret-color: black;
`;

const FakeInput = styled.div`
  position: absolute;
  left: 12px;
  bottom: 3px;
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 700;
  pointer-events: none;
  white-space: nowrap;
`;

const RangeLabel = styled.div`
  font-size: 10px;
  font-family: ${theme.fonts.primary};
  font-weight: 500;
  letter-spacing: 0.05em;
`;

const RangeInput = ({ label, suffix, ...rest }) => (
  <RangeInputWrapper>
    <RangeLabel>{label}</RangeLabel>
    <StyledInput {...rest} />
    <FakeInput>
      {rest.value} {suffix}
    </FakeInput>
  </RangeInputWrapper>
);

export default RangeInput;
