import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { useIntl } from '@jetshop/intl';
import { theme } from '../../../components/Theme';
import { getFilterQueryString } from '../../../helpers';
import useRecopartSearch from '../../../hooks/useRecopartSearch';
import Styled from './Style';
import { ReactComponent as SEicon } from '../../../assets/svg/SEicon.svg';
import { ReactComponent as VerificationCheck } from '../../../assets/svg/VerificationCheck.svg';
import { ReactComponent as InputError } from '../../../svg/InputError.svg';
import SearchField from '../../../components/SearchPage/SearchField';
import { useHistory, useLocation } from 'react-router';
import { MultiListFilters, useMultiListFilter } from './MultiListFilters';
import { useRegNrContext, useRegNrDispatch } from '../../../contexts/regnr';
import SearchFieldOriginalParts from '../../../components/Forms/SearchFieldOriginalParts/SearchFieldOriginalParts.js';
import useSearchForm from '../../../hooks/useSearchForm';

export const searchFieldStyles = css`
  margin: 0;

  ${theme.below.md} {
    flex: 1;
  }
  &[data-flight-searchfield] > * {
    display: flex;
    height: 100%;
    width: 100% !important;
    justify-content: center;

    input {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: ${theme.colors.abdDarkGrey};
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      outline: none;

      ::placeholder {
        text-transform: none;
      }

      ${theme.below.xs} {
        font-size: 16px;
        width: 308px;
      }
    }
  }
  [data-flight-searchfield-cancel] {
    display: none;
  }
  [data-flight-searchfield-flyout] {
    text-align: left;
    top: 100%;

    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      color: black;
      padding: 0.5rem;
      display: block;
    }
  }
`;

const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const ButtonWrapper = styled('div')`
  position: relative;
  background-color: #6cc400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 50%;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 3px;
  }

  :hover,
  :active {
    background-color: ${theme.colors.abdGreenHover} !important;
  }

  button {
    font-size: 0.875rem;
    font-weight: 700 !important;
    text-transform: uppercase;
    color: black;
  }

  .badge {
    position: absolute;
    pointer-events: none;
    color: white;
    font-size: 0.5rem;
  }

  ${theme.below.lg} {
    margin-top: 20px;
  }

  ${theme.below.sm} {
    width: 100%;
    margin-top: 20px;
  }
`;

const Button = styled('button')`
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  padding: 13px 20px 14px 20px;
  width: 100%;

  svg:hover {
    path {
      fill: ${theme.colors.abdGreen} !important;
    }
  }
`;

const InfoText = styled('p')`
  color: white;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  width: 90%;

  ${theme.below.lg} {
    font-size: 1rem;
  }
`;

const InputInformation = styled(InfoText)`
  margin-top: 8px;
  color: #c4c5c5;

  ${theme.below.md} {
    color: #fff;
  }
`;

const verificationCheckIcon = css`
  width: 40px;
`;

const inputErrorIcon = css`
  width: 40px;
  transform: rotate(-45deg);
`;

const HeroSearchForm = ({
  className,
  regInputPlaceholder,
  searchInputPlaceholder,
  searchButtonText,
  informationTextSecondary,
  informationText
}) => {
  const {
    searchString: recoSearchString,
    setSearchString,
    handleSearchStringChange,
    data: recopartCarModel,
    loading
  } = useRecopartSearch();

  const t = useIntl();
  const history = useHistory();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const searchForm = useSearchForm(searchTerm);

  const dispatch = useRegNrDispatch();
  const { id, manufacturer, model } = useRegNrContext();
  const { sendFilterQuery } = useMultiListFilter();

  useEffect(() => {
    const manufacturerItem = manufacturer?.items?.find(
      item => item.value === recopartCarModel?.carCodeGroupName
    );

    if (manufacturerItem) {
      sendFilterQuery({
        multiListFilters: [{ id, values: [manufacturerItem.value] }]
      });
      dispatch({
        type: 'addActiveFilterItem',
        filter: {
          ...manufacturer,
          activeItem: { ...manufacturerItem, label: manufacturerItem.value }
        }
      });
    }
  }, [recopartCarModel]);

  useEffect(() => {
    const modelItem = model?.items?.find(
      item => item.value === recopartCarModel?.carCodeName
    );

    if (modelItem) {
      dispatch({
        type: 'addActiveFilterItem',
        filter: {
          ...model,
          activeItem: { ...modelItem, label: modelItem.value }
        }
      });
    }
  }, [model.items, recopartCarModel]);

  useEffect(() => {
    if (
      manufacturer?.activeItem?.value !== recopartCarModel?.carCodeGroupName
    ) {
      setSearchString('');
    }
  }, [manufacturer.activeItem]);

  const onSubmitSearch = () => {
    const filter =
      manufacturer.activeItem || model.activeItem
        ? getFilterQueryString(
            2,
            manufacturer.activeItem.value,
            model.activeItem.value
          )
        : location.search;

    searchForm.term
      ? searchForm.triggerSearch(
          recopartCarModel?.carCodeGroupName,
          recopartCarModel?.carCodeName
        )
      : history.push(`/bil?${filter}`);
  };

  return (
    <Styled.InputFieldsContainer
      className={className}
      role="search"
      onSubmit={onSubmitSearch}
    >
      {informationText && <Styled.InfoText>{informationText}</Styled.InfoText>}
      <Styled.RegNumber>
        <Styled.RegIcon>
          <SEicon />
        </Styled.RegIcon>
        <Styled.RegNr>ABC123</Styled.RegNr>
        <Styled.RegInfoInput
          type="search"
          name="RegInfoInput"
          id="RegInfoInput"
          value={recoSearchString}
          onChange={handleSearchStringChange}
          placeholder={regInputPlaceholder}
          aria-label="Sök efter fordonsinfo med registreringsnummer"
        />
        {recopartCarModel?.nameOfCar && (
          <VerificationCheck className={verificationCheckIcon} />
        )}
        {recopartCarModel?.error && <InputError className={inputErrorIcon} />}
        {loading && <Styled.Spinner />}
      </Styled.RegNumber>
      {recopartCarModel?.nameOfCar && (
        <Styled.MessageContainer>
          {recopartCarModel?.nameOfCar}
        </Styled.MessageContainer>
      )}
      {recopartCarModel?.error && (
        <Styled.MessageContainer>
          {recopartCarModel?.errorMessage ||
            t('We could not find any car model')}
        </Styled.MessageContainer>
      )}

      <Styled.FilterListContainer>
        <MultiListFilters />
      </Styled.FilterListContainer>

      <Styled.SearchFieldContainer>
        <SearchFieldOriginalParts
          searchField={searchForm}
          searchInputPlaceholder={searchInputPlaceholder}
          setSearchTerm={setSearchTerm}
          carData={recopartCarModel}
        />
        {informationTextSecondary && (
          <InputInformation>{informationTextSecondary}</InputInformation>
        )}
        <ButtonWrapper>
          <Button disabled={loading}>
            <span>{searchButtonText}</span>
          </Button>
        </ButtonWrapper>
      </Styled.SearchFieldContainer>
    </Styled.InputFieldsContainer>
  );
};

SearchField.Cancel = CancelButton;

export default HeroSearchForm;
