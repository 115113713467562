import React from 'react';
import { ProductCard } from '../../../components/CategoryPage/ProductCard/ProductCard';
import { Carousel, CarouselItem } from '../../../components/ui/Carousel';

export function ProductGridCarousel({ products }) {
  if (!Array.isArray(products) && !(products.length > 0)) return null;

  return (
    <Carousel data-testid="product-carousel">
      {products.map(product => {
        return (
          <CarouselItem key={product.articleNumber}>
            <ProductCard product={product} />
          </CarouselItem>
        );
      })}
    </Carousel>
  );
}

export default ProductGridCarousel;
