import ActiveFiltersQuery from '@jetshop/core/components/DynamicRoute/ActiveFiltersQuery.gql';
import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import PaginationProvider, {
  usePagination
} from '@jetshop/core/components/Pagination/PaginationProvider';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';

import qs from 'qs';
import * as React from 'react';
import { Query, useQuery } from 'react-apollo';

const SearchPageBehaviour = props => {
  const pagination = usePagination();
  const { location, searchQuery, children } = props;
  const { offset, perPage } = React.useContext(PaginationContext);
  const activeSearchTerm = React.useRef();
  // Track the previous offset. If it has changed, we're paginating. If it
  // hasn't, we're just applying filters.
  const activeOffset = React.useRef(0);

  const { clearAllFilters } = useFilters();

  throwErrorInDev(
    !searchQuery,
    `You did not pass 'searchQuery' to SearchPageBehaviour`
  );

  const activeFilterQuery = useQuery(ActiveFiltersQuery);

  const searchTerm = React.useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }).term,
    [location]
  );

  React.useEffect(() => {
    if (activeSearchTerm.current && activeSearchTerm.current !== searchTerm) {
      // clearAllFilters();
    }
    activeSearchTerm.current = searchTerm;
  }, [clearAllFilters, searchTerm]);

  React.useEffect(() => {
    activeOffset.current = offset;
  }, [offset]);

  if (pagination === undefined) {
    return (
      <PaginationProvider>
        <SearchPageBehaviour {...props} />
      </PaginationProvider>
    );
  }

  // Ideally this component would use the data from usePagination, but due to
  // how the shouldComponentUpdate method in FilterContainer works it prevents
  // rendering if we don't do it this way.
  return searchTerm ? (
    <Query
      variables={{
        term: searchTerm,
        first: perPage,
        limit: perPage,
        filters: activeFilterQuery.data?.activeFilters || null,
        offset: activeOffset.current === offset ? 0 : offset,
        client: !__IN_SERVER__
      }}
      query={searchQuery}
      fetchPolicy="cache-and-network"
    >
      {result => {
        const totalResults = result?.data?.search?.products?.totalResults;
        const filters = result?.data?.search?.products?.filters;

        return (
          <>
            {children({
              result,
              total: totalResults,
              filters,
              term: searchTerm,
              loading: result.loading,
              error: result.error
            })}
          </>
        );
      }}
    </Query>
  ) : (
    children({
      term: searchTerm,
      loading: false,
      error: null,
      total: 0,
      result: {},
      filters: {}
    })
  );
};

export default SearchPageBehaviour;
