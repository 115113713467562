import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import MaxWidth from '../../../components/Layout/MaxWidth';
import { ReactComponent as ArrowRight } from '../../../assets/svg/ArrowRight.svg';
import { ReactComponent as Accent } from '../../../assets/svg/Accent.svg';
import { ReactComponent as Play } from '../../../assets/svg/play.svg';
import { ReactComponent as Tiktok } from '../../../assets/svg/tiktok_white.svg';
import Image from '@jetshop/ui/Image/Image';
import useCleanProps from '../../../hooks/useCleanProps';

const VideoWrapper = styled('div')`
  background-color: ${theme.colors.abdLightGrey};
  position: relative;
`;

const VideoContainer = styled('div')`
  width: 100%;
  height: 611px;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: 'Barlow';

  ${theme.below.lg} {
    height: 507px;
  }
`;

const ImgContainer = styled('div')`
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    ${theme.below.sm} {
      height: 400px;
    }
  }
`;

const TextContainer = styled('div')`
  color: ${theme.colors.white};
`;

const PlayContainer = styled('div')`
  position: absolute;
  top: 42%;
  left: 46%;

  ${theme.below.sm} {
    left: 35%;
    top: 38%;
  }
`;

const Title = styled('div')`
  position: absolute;
  top: 40%;
  left: 6%;
  width: 425px;
  font-weight: 800;
  font-size: 60px;
  line-height: 110%;

  ${theme.below.xl} {
    left: 8%;
    top: 40%;
  }

  ${theme.below.lg} {
    left: 10%;
    right: 10%;
    top: 40%;
    font-size: 40px;
    width: 250px;
  }
  ${theme.below.md} {
    top: 40%;
  }

  ${theme.below.sm} {
    top: 64%;
  }
`;

const InfoText = styled('div')`
  position: absolute;
  top: 85%;
  left: 6%;
  max-width: 556px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 12px;

  ${theme.below.xl} {
    left: 8%;
    top: 85%;
  }

  ${theme.below.lg} {
    font-size: 16px;
    line-height: 22.4px;
    left: 9%;
  }
  ${theme.below.md} {
    left: 10%;
    right: 10%;
    top: 85%;
  }
  ${theme.below.sm} {
    top: 75%;
    left: 16%;
  }
`;

const VideoBlock = props => {
  const { backgroundImage, titleText, infoText } = useCleanProps(props);
  return (
    <VideoWrapper>
      <MaxWidth>
        <VideoContainer>
          <ImgContainer>
            {backgroundImage && (
              <Image alt="" fillAvailableSpace={true} src={backgroundImage} />
            )}
            <TextContainer>
              <PlayContainer>
                <Play />
              </PlayContainer>
              <Title>
                {titleText}
                <Accent />
              </Title>
              <InfoText>
                <Tiktok />
                {infoText}
                <ArrowRight />
              </InfoText>
            </TextContainer>
          </ImgContainer>
        </VideoContainer>
      </MaxWidth>
    </VideoWrapper>
  );
};

export default VideoBlock;
