import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import Campaign from '../../../components/ui/Campaign';

const Grid = styled('div')`
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  gap: 23px;

  ${theme.below.md} {
    gap: 12px;
  }

  ${theme.below.sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const CampaignWrapper = styled('div')`
  background-color: ${theme.colors.white};
  width: 100%;
`;

const SmallCampaignGrid = ({ categories }) => {
  if (!Array.isArray(categories) || !categories?.length > 0) return null;

  return (
    <Grid>
      {categories?.map(category => {
        return (
          <CampaignWrapper key={category.key}>
            <Campaign columns={2} {...category} />
          </CampaignWrapper>
        );
      })}
    </Grid>
  );
};
export default SmallCampaignGrid;
